@import '~antd/dist/antd.less';

.menu__logo {
  margin:10px;
  width: 122px;
  height: 50px;
  float: left;
}

.menu__logo a {
  display: inline-block;
  font-size: 20px;
  padding: 19px 20px;
}

.menu__container .ant-menu-item {
  padding: 0px 5px;
}

.menu__container .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menu__container .ant-menu-item a,
.menu__container .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menu__container .ant-menu-horizontal {
  border-bottom: none;
}

.menu__container .menu_left {
  float: left;
}

.menu__container .menu_rigth {
  float: right;
}

.menu__mobile-button {
  cursor: pointer;
  float: right;
  height:'100%';
  vertical-align: middle;
  marginBottom:30px;
  display: none !important; /* use of important to overwrite ant-btn */
}

.menu_drawer .ant-drawer-body {
  padding: 0 !important;
}

/* align header of Drawer with header of page */
.menu_drawer .ant-drawer-header {
  padding: 14px 24px !important;
}

@media (max-width: 950px) {
  .menu__mobile-button {
    display: inline-block !important;
  }

  .menu_left,
  .menu_rigth {
    display: none;
  }

  .menu__logo a {
    margin-left: -20px;
  }

  .menu__container .ant-menu-item,
  .menu__container .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .menu__logo a {
    padding: 10px 20px;
  }
}

@primary-color: #0A3980;@layout-header-background: #0A3980;@success-color: #79DE76;@font-family: Source Sans Pro;@heading-color-dark: fade(@white, 100%);@text-color-dark: fade(@white, 95%);@text-color-secondary-dark: fade(@white, 85%);@border-radius-base: 5px;@btn-primary-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.2);@font-size-base: 18px;@font-size-lg: @font-size-base + 2px;@font-size-sm: @font-size-base - 2px;